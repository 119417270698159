<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-3">
        What is the primary purpose of the General Chemistry Laboratory Safety Rules (check all 3
        that apply)?
      </p>

      <p v-for="option in optionsShuffled" :key="option.value" class="mb-0 mt-0">
        <v-checkbox v-model="inputs.studentAnswers" :value="option.value" class="ml-6 mb-n4 mt-0">
          <template v-slot:label>
            <stemble-latex :content="option.text" />
          </template>
        </v-checkbox>
      </p>
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import StembleLatex from '@/tasks/components/StembleLatex';
import {seededShuffle} from '@/courses/utils/seededShuffle';

export default {
  name: 'ChemOleMiss116SSQ0Q1',
  components: {StembleLatex},
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        studentAnswers: [],
      },
      options: [
        {text: 'To increase safety', value: 'safety'},
        {text: 'To confuse the student', value: 'confuse'},
        {text: 'To decrease risk', value: 'risk'},
        {text: 'To overwhelm the student with new information', value: 'overwhelm'},
        {text: 'To inform proper behavior', value: 'behavior'},
        {text: "To meet the department's writing quota", value: 'quota'},
      ],
    };
  },
  computed: {
    seed() {
      return this.$gate.user?.id || 1;
    },
    optionsShuffled() {
      return seededShuffle(this.options, this.seed);
    },
  },
};
</script>
